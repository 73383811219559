var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inFormation df" }, [
    _c("div", { staticClass: "main-left" }, [
      _c("div", { staticClass: "photo-box" }, [
        _c("img", {
          attrs: {
            src: "https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "Pname", staticStyle: { color: "#a0a1a1" } }, [
          _vm._v(_vm._s(_vm.detailForm.name)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "Pname" }, [
          _vm._v(_vm._s(_vm.detailForm.Telephone)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bgwh mb20 p20" }, [
        _c("div", { staticClass: "bbe pb10" }, [_vm._v("操作日志")]),
        _vm._v(" "),
        _vm.isusertype
          ? _c(
              "div",
              { staticClass: "ova" },
              _vm._l(_vm.journal, function (item, index) {
                return _c("div", { key: index, staticClass: "f14 bbe" }, [
                  _c("div", { staticClass: "g3 mt5 mt15 mb15" }, [
                    _vm._v(_vm._s(item.note)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g9 mb15" }, [
                    _vm._v(_vm._s(item.create_time)),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20 tc" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "", round: "" },
                on: { click: _vm.switchs },
              },
              [_vm._v(_vm._s(_vm.isusertypeName))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-right", staticStyle: { position: "relative" } },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "border-card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          _vm._l(_vm.followUpList, function (event, index) {
            return _c(
              "el-tab-pane",
              {
                key: event.customer_data_id,
                attrs: {
                  label: event.uname,
                  name: String(index),
                  dataId: event.customer_data_id,
                },
              },
              [
                _c("div", { staticClass: "fl tabLeft" }, [
                  _c("div", { staticClass: "RequiredInfosrmation" }, [
                    _c("h3", [_vm._v("基本信息")]),
                    _vm._v(" "),
                    _c("ul", [
                      _c(
                        "li",
                        { staticStyle: { "border-right-width": "0px" } },
                        [
                          _c("div", { staticClass: "classWidth" }, [
                            _vm._v("客户名称:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "innerWidth innerRight" }, [
                            _vm._v(_vm._s(_vm.detailForm.name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "div",
                          { staticStyle: { width: "33% !important" } },
                          [_vm._v("学员来源(花名):")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "innerWidth innerRight" }, [
                          _vm._v(_vm._s(_vm.detailForm.flower_name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { staticClass: "classWidth" }, [
                          _vm._v("邮箱:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "innerWidth innerRight" }, [
                          _vm._v(_vm._s(_vm.detailForm.mailbox)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { staticClass: "classWidth" }, [
                          _vm._v("电话:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "innerWidth innerRight",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.detailForm.Telephone) +
                                "\n                                    "
                            ),
                            _c("span", {
                              staticStyle: { "padding-left": "15px" },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticStyle: { "border-right-width": "0px" } },
                        [
                          _c("div", { staticClass: "classWidth" }, [
                            _vm._v("微信:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "innerWidth innerRight" }, [
                            _vm._v(_vm._s(_vm.detailForm.WeChat)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { staticClass: "classWidth" }, [
                          _vm._v("QQ:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "innerWidth innerRight" }, [
                          _vm._v(_vm._s(_vm.detailForm.QQ)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.show2
                        ? _c("li", [
                            _c("div", { staticClass: "classWidth" }, [
                              _vm._v("联系电话2:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "innerWidth",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.detailForm.Telephone2) +
                                    "\n                                    "
                                ),
                                _c("el-button", {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "6px",
                                    top: "6px",
                                    padding: "4px 6px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-minus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.show3
                        ? _c("li", [
                            _c("div", { staticClass: "classWidth" }, [
                              _vm._v("联系电话3:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "innerWidth",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.detailForm.Telephone3) +
                                    "\n                                    "
                                ),
                                _c("el-button", {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "6px",
                                    top: "6px",
                                    padding: "4px 6px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-minus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-bottom-width": "1px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "7.2%", height: "40px" } },
                            [_vm._v("备注:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "92.2%",
                                height: "40px",
                                "line-height": "40px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.detailForm.remarks))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "SelectiveInformation" },
                    [
                      _c("h3", [_vm._v("背景意向")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("当前学历:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [_vm._v(_vm._s(_vm.educationText))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("意向地区:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.detailForm.areaOfInterest
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "classWidth" }, [
                                      _vm._v("报考项目:"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "intentionWidth" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.baokaixiangmu,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.baokaixiangmu)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("毕业专业:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.detailForm.GraduationMajor)
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("意向专业:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "white-space",
                                            effect: "dark",
                                            content: _vm.Intended,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "select" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.Intended)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "classWidth" }, [
                                      _vm._v("意向简章:"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "intentionWidth" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.General,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(_vm.General)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("意向院校:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "white-space",
                                            effect: "dark",
                                            content: _vm.ins,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "select" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.ins)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "classWidth" }, [
                                      _vm._v("预算:"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "intentionWidth" },
                                      [_vm._v(_vm._s(_vm.detailForm.budget))]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("span", { staticClass: "classWidth" }, [
                                    _vm._v("毕业院校:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "intentionWidth" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.detailForm.GraduatedFrom)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function ($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { name: "0" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("h3", { staticClass: "title" }, [
                                  _vm._v("其他信息"),
                                ]),
                                _vm._v(" "),
                                _c("h5", [_vm._v("   (点击展开/收缩)")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("性别:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "input-show innerWidth",
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: _vm.detailForm.sex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "sex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.sex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "0" } },
                                                    [_vm._v("男")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "1" } },
                                                    [_vm._v("女")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("预计入学:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [_vm._v(_vm._s(_vm.entranceTextEs))]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("岗位:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(_vm.detailForm.post) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("学术成绩:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.detailForm.achievements
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("年龄:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [_vm._v(_vm._s(_vm.detailForm.Age))]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("座机:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.detailForm.Landline)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("职业:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detailForm.occupation
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("语言成绩:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.detailForm.language)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("行业:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.detailForm.industry)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "classWidth" },
                                            [_vm._v("护照号码:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "innerWidth" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.detailForm.Passport)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "classWidth" },
                                              [_vm._v("发起沟通网址:")]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.detailForm.communication
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "classWidth" },
                                              [_vm._v("性格:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "innerWidth" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.detailForm.hobby)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { xs: 24 } }, [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            "border-bottom-width": "1px",
                                            float: "left",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { width: "12%" } },
                                            [_vm._v("详细住址:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "83%",
                                                "padding-left": "20px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.detailForm.address)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "border-bottom-width": "1px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { width: "12%" } },
                                          [_vm._v("客户标签:")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "label-peer" },
                                          [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.detailForm.labelpeer,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.detailForm,
                                                      "labelpeer",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "detailForm.labelpeer",
                                                },
                                              },
                                              _vm._l(
                                                _vm.customer,
                                                function (item) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.id,
                                                        disabled: "",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fl tabRight" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "RequiredInfosrmation",
                        staticStyle: { padding: "0", "margin-top": "-8px" },
                      },
                      [
                        _c("el-row", [
                          _c(
                            "ul",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "padding-left": "0",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "li",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c("div", { staticStyle: { width: "33%" } }, [
                                    _vm._v("学员级别:"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-cascader", {
                                    staticStyle: {
                                      border: "none",
                                      "padding-left": "0",
                                      width: "66.6666%",
                                    },
                                    attrs: {
                                      options: _vm.StudentLevel,
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.detailForm.Student,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.detailForm, "Student", $$v)
                                      },
                                      expression: "detailForm.Student",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-timeline",
                      _vm._l(_vm.activities, function (activity, index) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: index,
                            attrs: {
                              placement: "top",
                              timestamp: activity.create_time,
                            },
                          },
                          [
                            _c("span", { staticClass: "eD8CF0 mr10" }, [
                              _vm._v(_vm._s(activity.type)),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(activity.create_realnaem)),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(activity.content))]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.dialogShow
      ? _c("div", { staticClass: "drag" }, [
          _c(
            "div",
            {
              directives: [{ name: "drag", rawName: "v-drag" }],
              staticClass: "drag_box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("来电提醒")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "iconfont icon-cha",
                  on: { click: _vm.DialogClick },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "Main" }, [
                _vm._v("\n                您有\n                "),
                _c("span", [_vm._v(_vm._s(_vm.calls))]),
                _vm._v(" "),
                _c("span", [_vm._v("（" + _vm._s(_vm.MobileAddress) + "）")]),
                _vm._v("的来电请您到客户列表电话条处接听\n            "),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }