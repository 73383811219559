import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIttnxm, Customerinfo, getLabelpeer, getEntrance, FollwupIndex as _FollwupIndex, getCustomerLevel, getGraduationMajor, MyCustomerCustomerlog } from '@/api/ceshi_xiugai/whole';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
export default {
  data: function data() {
    return {
      //禁止改
      calls: '',
      MobileAddress: '',
      dialogShow: false,
      customer_id: this.$route.query.Cid,
      customer_data_id: this.$route.query.CData_id,
      editRole: this.$route.query.editRole,
      pageTop: this.$route.query.page,
      pagesizeTop: this.$route.query.pagesize,
      keyTop: this.$route.query.key,
      Search: this.$route.query.Search,
      Student: this.$route.query.customer_level,
      list_typeEs: this.$route.query.list_type,
      activeNames: ['1'],
      reverse: true,
      isusertype: false,
      show2: false,
      show3: false,
      isusertypeName: '查看全部',
      journal: [],
      Intended: '',
      //意向专业name值
      General: '',
      //意向简章name值
      ins: '',
      //意向院校name值
      IntendedMajor: [],
      GeneralRules: [],
      institu: [],
      entranceTextEs: '',
      detailForm: {
        name: '',
        //名称
        flower_name: '',
        //花名
        flowerNameId: 0,
        //花名id
        category: '',
        //类别
        agent_num: '',
        //花名码
        Telephone: '',
        //电话1
        cphoneAdress: '',
        //归属地1
        Telephone2: '',
        //电话2
        cphoneAdress2: '',
        //归属地2
        Telephone3: '',
        //电话3
        cphoneAdress3: '',
        //归属地3
        mailbox: '',
        //邮箱
        QQ: '',
        //qq
        remarks: '',
        //备注
        WeChat: '',
        //微信
        educationText: '',
        //当前学历
        areaOfInterest: '',
        //意向地区
        projectTextId: '',
        //报考项目id
        projectText: '',
        //报考项目
        GraduationMajor: '',
        //毕业专业
        IntendedMajor: [],
        //意向专业
        GeneralRules: [],
        //意向简章
        GraduatedFrom: '',
        //毕业院校
        institu: [],
        //意向院校所选的值
        budget: '',
        //预算
        sex: '',
        //性别
        entranceText: '',
        //预计入学id
        entranceTexts: '',
        //预计入学val
        post: '',
        //岗位
        achievements: '',
        //学术成绩
        Age: '',
        //年龄
        Landline: '',
        //座机
        occupation: '',
        //单位
        language: '',
        //语言成绩
        industry: '',
        //行业
        Passport: '',
        //护照号
        communication: '',
        //发起沟通的网址
        hobby: '',
        //性格
        address: '',
        //详细地址
        labelpeer: [],
        //客户标签
        Student: [] //学员级别
      },
      baokaixiangmu: '',
      educationText: '',
      activities: [],
      // 跟进记录
      StudentLevel: [],
      //学员级别
      customer: [],
      //客户标签
      radio: '1',
      cDataIDE: 1,
      followUpList: [],
      activeName: '0'
    };
  },
  mounted: function mounted() {
    var that = this;
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.dialogShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.dialogShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
    this.GetDetails(this.customer_id, this.customer_data_id);
    this.Customerlog(this.customer_id, this.customer_data_id);
    this.education();
  },
  methods: {
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    },
    handleClick: function handleClick(tab) {
      this.cDataIDE++;
      // this.activeName = tab.name
      this.customer_data_id = tab.$attrs.dataId;
      this.GetDetails(this.customer_id, this.customer_data_id);
      this.Customerlog(this.customer_id, this.customer_data_id);
    },
    GetDetails: function GetDetails(customer_id, customer_data_id) {
      var _this = this;
      //编辑获取详情
      Customerinfo({
        customer_id: customer_id,
        share_flag: '1',
        customer_data_id: customer_data_id
      }).then(function (res) {
        var data = res.data;
        _this.followUpList = data.share_list;
        // this.ads=data.share_list[0].uname
        _this.customer_id = res.data.customer_id;
        if (_this.cDataIDE == 1) {
          _this.FollwupIndex(_this.customer_id, res.data.share_list[0].customer_data_id);
        } else {
          _this.FollwupIndex(_this.customer_id, _this.customer_data_id);
        }
        //意向专业
        _this.detailForm.IntendedMajor = [];
        _this.IntendedMajor = [];
        data.ittnzy.forEach(function (item) {
          _this.IntendedMajor.push(item);
          _this.Intended = item.title.toString();
          _this.detailForm.IntendedMajor.push(item.id);
        });
        //意向简章
        _this.GeneralRules = [];
        _this.detailForm.GeneralRules = [];
        data.ittnjz.forEach(function (item) {
          _this.GeneralRules.push(item);
          _this.General = item.title.toString();
          _this.detailForm.GeneralRules.push(item.id);
        });
        // 意向院校
        _this.institu = [];
        _this.detailForm.institu = [];
        data.ittnyx.forEach(function (item) {
          _this.institu.push(item);
          _this.ins = item.title.toString();
          _this.detailForm.institu.push(item.id);
        });
        // 花名
        data.source_client_info.forEach(function (item) {
          _this.detailForm.flowerNameId = item.id;
          _this.detailForm.flower_name = item.flower_name;
          _this.detailForm.category = item.leibie;
          _this.detailForm.agent_num = item.number;
        });
        // 公海、私海
        var khbq = data.labelpeer.split(',').map(function (res) {
          return res;
        });
        _this.detailForm.name = data.cname;
        _this.detailForm.Telephone = data.mobile;
        _this.detailForm.cphoneAdress = data.mobile_address;
        _this.detailForm.Telephone2 = data.mobile1;
        _this.detailForm.cphoneAdress2 = data.mobile1_address;
        _this.detailForm.Telephone3 = data.mobile2;
        _this.detailForm.cphoneAdress3 = data.mobile2_address;
        _this.detailForm.mailbox = data.cemail;
        _this.detailForm.QQ = data.qq;
        _this.detailForm.remarks = data.notes;
        _this.detailForm.WeChat = data.wxnum;
        getGraduationMajor().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (data.graduation_xueli == 0) {
              _this.educationText = '';
            } else if (item.id == String(data.graduation_xueli)) {
              _this.educationText = item.value;
            }
          });
        });
        if (data.graduation_xueli == '0') {
          _this.detailForm.educationText = '';
        } else {
          _this.detailForm.educationText = String(data.graduation_xueli);
        }
        _this.detailForm.areaOfInterest = data.ittngj;
        getIttnxm().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == data.ittnxl) {
              if (data.ittnxl == 0) {
                _this.baokaixiangmu = '';
                alert(11111);
              } else {
                _this.baokaixiangmu = item.value;
              }
            }
          });
        });
        if (data.ittnxl == '0') {
          _this.detailForm.projectTextId = '';
        } else {
          _this.detailForm.projectTextId = String(data.ittnxl);
        }
        _this.detailForm.GraduationMajor = data.graduation_major;
        _this.detailForm.GraduatedFrom = data.graduate_school;
        _this.detailForm.budget = data.budget;
        _this.detailForm.sex = String(data.sex);
        getEntrance().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == _this.detailForm.entranceTexts) {
              _this.entranceTextEs = item.value;
              _this.detailForm.entranceText = item.value;
            }
          });
          _this.entrance = res.data;
        });
        if (data.entrance == '0') {
          _this.detailForm.entranceTexts = '';
        } else {
          _this.detailForm.entranceTexts = String(data.entrance); //预计入学
        }
        _this.detailForm.post = data.post;
        _this.detailForm.achievements = data.academic_achievements;
        if (data.age == '0') {
          _this.detailForm.Age = '';
        } else {
          _this.detailForm.Age = String(data.age);
        }
        _this.detailForm.Landline = data.telephone;
        _this.detailForm.occupation = data.occupation;
        _this.detailForm.language = data.language_achievement;
        _this.detailForm.industry = data.industry;
        _this.detailForm.Passport = data.passport_number;
        _this.detailForm.communication = data.communication_website;
        _this.detailForm.address = data.detailed_address;
        _this.detailForm.labelpeer = khbq;
        _this.detailForm.Student = data.customer_level;
      });
    },
    switchs: function switchs() {
      //操作日志开关
      if (this.isusertype == false) {
        this.isusertype = true;
        this.isusertypeName = '收起内容';
      } else {
        this.isusertype = false;
        this.isusertypeName = '查看全部';
      }
    },
    Customerlog: function Customerlog(log, customer_data_id) {
      var _this2 = this;
      //操作日志
      MyCustomerCustomerlog({
        customer_id: log,
        customer_data_id: customer_data_id
      }).then(function (res) {
        _this2.journal = res.data.list;
      });
    },
    education: function education() {
      var _this3 = this;
      //进入页面加载的接口
      getGraduationMajor().then(function (res) {
        //当前学历
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this3.educations = res.data;
      });
      getIttnxm().then(function (res) {
        //报考项目
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this3.projects = res.data;
      });
      getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this3.customer = res.data;
      });
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this3.StudentLevel = res.data;
      });
      console.log(this.cDataIDE);
      this.FollwupIndex(this.customer_id, this.cDataIDE);
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    FollwupIndex: function FollwupIndex(customer_id, customer_data_id) {
      var _this4 = this;
      this.activities = '';
      _FollwupIndex({
        id: Number(customer_id),
        share_flag: '1',
        is_all: 1,
        is_realname: 1,
        customer_data_id: Number(customer_data_id)
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this4.activities = res.data;
      });
    }
  }
};